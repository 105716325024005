import React, { Suspense, useRef } from 'react';
import root from 'react-shadow/styled-components';
import { PortalContextProvider, Theme, ThemeProvider } from '@amzn/storm-ui-v3';
import StormUIFonts from '@amzn/d16g-rodeo-website-shared-components/src/utils/storm-styles/fonts/stormUIFonts';
import { defaultRodeoMFETheme } from '@amzn/d16g-rodeo-website-shared-components/src/utils/storm-styles/styles/defaultRodeoMFETheme';

interface Props {
  /** StormUI theme. If not provided, a default Rodeo MFE theme
   *  will be used to instantiate the theme provider */
  theme?: Theme;
  /** Content to be displayed inside the shadow DOM */
  children: React.ReactNode;
}

/**
 * Component that wraps its content inside a shadow DOM
 * and a StormUI theme provider. The draw to this wrapper
 * is that it configures StormUI styles/fonts correctly
 * inside the shadow DOM.
 * @param props Component props
 * @returns ShadowDOMWrapper component.
 */
const BulkEditShadowDOMWrapper = (props: Props) => {
  const { theme, children } = props;
  const updatedTheme = theme || defaultRodeoMFETheme;
  const shadowPortalId = 'shadow-portal';
  const portalRef = useRef<HTMLDivElement>(null);

  return (
    <root.div
      style={{
        fontFamily: 'Amazon Ember, Arial, sans-serif',
        fontSize: '0.8125rem',
      }}
    >
      <style type="text/css">{StormUIFonts}</style>
      <div>
        <PortalContextProvider portalElementRef={portalRef}>
          <Suspense fallback={null}>
            <ThemeProvider
              theme={{
                ...updatedTheme,
                popover: {
                  ...updatedTheme.popover,
                  portalElementId: shadowPortalId,
                },
              }}
            >
              {children}
            </ThemeProvider>
          </Suspense>
        </PortalContextProvider>
        <div id={shadowPortalId} ref={portalRef} />
      </div>
    </root.div>
  );
};

export default BulkEditShadowDOMWrapper;
